export function formatTime(timeString:string) {
    // 使用正则表达式提取分钟和秒数
    var match = timeString.match(/(\d+)分(\d+)秒/);
    
    if (match) {
        var minutes = match[1];
        var seconds = match[2];
        
        // 确保秒数是两位数
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }
        
        return minutes + ':' + seconds;
    } else {
        throw new Error("Invalid time format");
    }
}

// 通过 User-Agent 判断 是否手机还是
export function isMobile() {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
  }
  
