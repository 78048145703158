import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  UnorderedListOutline,
  LeftOutline,
  RightOutline,
  ArrowsAltOutline,
  ShrinkOutline,
  DeleteOutline,
  LoopOutline,
} from "antd-mobile-icons";
import { Mp3Player } from "../components/Mp3Player";
import { isMobile } from "../utils/commentUtil";
import "./layout_index.css";
import {
  Button,
  List,
  Space,
  Tabs,
  Tag,
  Toast,
  Image,
  Footer,
  CenterPopup,
  Popup,
  AutoCenter,
} from "antd-mobile";
import pause from "../assets/pause.png";
import play from "../assets/play.png";
import playing from "../assets/playing.gif";
import {
  httpDeleteMusic,
  httpAddMusic2Playlist,
  httpPostPlaylist,
  httpSignMp3,
  httpSignImage,
} from "../apis/home";
import GoCaptcha from "go-captcha-react";
import Cookies from "js-cookie";

interface CaptchaData {
  thumb_x: number;
  thumb_y: number;
  thumb_w: number;
  thumb_h: number;
  master_bg: string;
  thumb_bg: string;
  captcha_key: string;
}

enum PlayMode {
  Loop = 0, // 循环
  Random = 1, // 随机
}

// 页面是否首次加载 解决频繁获取歌单链接
let isPageFirstLoad: boolean = true;
// 第一次添加歌曲进歌单时打开歌单
let isPlayListFirstLoad: boolean = true;
// 当前Play下标
let currentPlayIndex: number = 0;
// 随机列表
let myRandomList: Array<number> = [];
// 全局播放器对像
let myApObject: any = null;
// 播放次数
let currentPlayCount: number = 1;
// 播放模式 0 循环 1随机
let currentPlayMode: number = PlayMode.Loop;
export default function Layout() {
  // 滑动验证码
  const [sliderData, setSliderData] = useState<CaptchaData>(); // 返回滑动验证码的配置信息
  const [mp3Data, setMp3Data] = useState<any>({}); // mp3的相关信息
  const [popvisible, setPopVisible] = useState(false);
  const [popvisible2, setPopVisible2] = useState(false);
  const [userName, setUserName] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [ap, setAp] = useState<any>(null);
  // 当前播放的item
  const [currentPlay, setCurrentPlay] = useState<number>(0);
  // 标准播放
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>("");
  //
  const navigate = useNavigate();
  const myRef = useRef(null);
  // 全局音乐数据
  const [musics, setMusics] = useState<any>([]);
  // 展开歌单
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  // 播放次数
  const [tagIndex, setTagIndex] = useState<number>(1);
  // 播放模式 0 循环 1随机
  const [playMode, setPlayMode] = useState<number>(PlayMode.Loop);
  // 当前滑动状态下激活的item下标
  const [activeItem, setAcviteItem] = useState<number>(-1);

  function changePlayMode() {
    // 重置播放列表
    myRandomList = myApObject.list.audios.map((item: any, index: any) => {
      return index;
    });

    if (playMode === PlayMode.Loop) {
      setPlayMode(PlayMode.Random);
      currentPlayMode = PlayMode.Random;
    } else {
      setPlayMode(PlayMode.Loop);
      currentPlayMode = PlayMode.Loop;
    }
  }

  // 播放mp3
  function onPlay() {
    myApObject.toggle();
    setIsPaused(true);
  }

  function onPrev() {
    if (currentPlayIndex === 0) {
      currentPlayIndex = myApObject.list.audios.length - 1;
    } else {
      currentPlayIndex = currentPlayIndex - 1;
    }
    setCurrentPlay(currentPlayIndex);
    myApObject.list.switch(currentPlayIndex);
  }

  function onNext() {
    if (myApObject.list.audios.length - 1 === currentPlayIndex) {
      currentPlayIndex = 0;
    } else {
      currentPlayIndex = currentPlayIndex + 1;
    }
    setCurrentPlay(currentPlayIndex); // 切换列表下标
    myApObject.list.switch(currentPlayIndex); // 执行aplay播放
  }

  function removeRepeat(item: any) {
    let isRepeat = false;
    myApObject.list.audios.map((d: any, idx: number) => {
      if (d.id === item.id) {
        // 重置播放下标
        currentPlayIndex = idx;
        setCurrentPlay(currentPlayIndex);
        myApObject.list.switch(idx);
        myApObject.play();
        setIsPaused(true);
        isRepeat = true;
      }
    });
    return isRepeat;
  }

  // 添加歌曲到歌单
  async function addMusic2List(e: any, item: any, index: any) {
    addPlayListHandler(item);
    // 关联滑动验证码信息
    setMp3Data(item);
  }

  async function addPlayListHandler(item: any) {
    if (removeRepeat(item)) {
      Toast.show({
        content: "歌曲已存在歌单中",
        duration: 1000,
      });
      scrollTopBar(item);
      return;
    }

    const { id, name, album_cover_image, singer, lrc_text } = item;

    const res: any = await httpAddMusic2Playlist({
      id,
      name,
      album_cover_image: album_cover_image,
      singer,
      lrc_text,
      device_id: window.visitorId,
      captcha_key: mp3Data?.captchakey, // 滑动验证码会话id
      x: mp3Data?.x, // 滑动后的x的位置
      y: mp3Data?.y, // 滑动后的y的位置
    });

    // 接口调用完成后 删除过期的滑块x,y位置信息 和验证码key
    delete mp3Data?.x;
    delete mp3Data?.y;
    delete mp3Data?.captchakey;
    // 验证码
    if (res?.code == undefined) {
      setSliderData({ ...res });
      setPopVisible(true);
      return;
    }
    // 超出游客日访问量 或 超出游客免费时间 请登录或注册会员
    if (res?.code == 5004 || res?.code == 5003) {
      setMsg(res?.error);
      setPopVisible2(true);
      return;
    }

    // 异常的业务信息
    if (res?.code !== 200) {
      Toast.show({
        maskClickable: false,
        content: res?.error,
        duration: 3000,
      });
      return;
    }

    let obj = {
      id: item.id,
      name: item.name,
      artist: item.singer,
      album: item.album,
      url: res.data,
      cover: item.album_cover_image,
      lrc: `${item.lyrics}`,
      theme: "#ebd0c2",
      // selected:true,
      maxPlayCount: currentPlayCount,
    };

    // 操作播放器的真实数据
    if (myApObject.list.audios.length === 0) {
      setCurrentPlay(0);
      currentPlayIndex = 0;
    }

    // 添加全局播放器
    myApObject.list.add(obj);
    // 更新播放列表显示
    setMusics([...myApObject.list.audios]);
    // 更新选中列表
    // currentSelectList.push(myApObject.list.audios.length - 1);
    // 立刻播放加进播放列表的歌
    currentPlayIndex = myApObject.list.audios.length - 1;
    setCurrentPlay(currentPlayIndex);
    myApObject.list.switch(currentPlayIndex);
    myApObject.play();
    setIsPaused(true);

    Toast.show({
      maskClickable: false,
      content: "添加成功",
      duration: 1000,
      afterClose: () => {
        setPopVisible(false);
      },
    });

    // 新添加的歌曲渲染结束后 再滚动
    let timer = setTimeout(() => {
      clearTimeout(timer);
      scrollTopBar(item);
    }, 300);
  }

  // 滚动到播放的歌曲位置
  function scrollTopBar(data: any) {
    // 获取当前item在播放列表的index
    const index = myApObject.list.audios.findIndex((item: any) => {
      return data.id === item.id;
    });
    const obj = myRef.current as unknown as HTMLDivElement;
    obj.scrollTop = 52 * index;
  }

  async function deleteMusic(item: any, index: number) {
    if (!userName) {
      Toast.show({
        content: "请您登录后再删除...",
        duration: 1000,
      });
      return;
    }

    const res: any = await httpDeleteMusic({
      device_id: window.visitorId,
      id: item.id,
    });

    if (res.code !== 200) {
      Toast.show({
        content: res.msg,
        duration: 1000,
      });
      return;
    }
    const restData = myApObject.list.audios.filter(
      (obj: any) => item.id !== obj.id
    );
    //  更新显示数据
    setMusics([...restData]);
    // 删除aplayer中的歌曲
    myApObject.list.remove(index);

    // 重置播放gif下标
    if (index < currentPlayIndex) {
      currentPlayIndex = currentPlayIndex - 1;
      setCurrentPlay(currentPlayIndex);
    }

    // 当删除的歌正在播放
    if (currentPlayIndex === index) {
      // 如果是最后一首歌 其它情况正常
      let last = myApObject.list.audios.length;
      if (index === last) {
        myApObject.list.switch(last - 1);
        setCurrentPlay(last - 1);
        currentPlayIndex = last - 1;
      }
    }
  }

  function getRandomElement(arr: any) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const element = arr[randomIndex];
    arr.splice(randomIndex, 1); // 从数组中移除该元素
    return element;
  }

  //  暂停选中
  function stopSelected() {
    ap?.toggle();
    setIsPaused(false);
  }

  // 点击歌单中mp3播放
  function onPlaySelected(url: string, index: number, e: any) {
    // 点击item播放
    if (e.target.nodeName === "DIV") {
      // ap.list.show();
      ap?.list.switch(index);
      ap?.play();
      currentPlayIndex = index;
      setCurrentPlay(currentPlayIndex);
      setIsPaused(true);

      setAcviteItem(index);
    }
  }

  function onApInit(ap: any) {
    setAp(ap);
    myApObject = ap;
    // 附加事件监听器
    ap?.on("ended", handleEnded);
    // 播放异常事件 盗源认证过期了
    ap?.on("error", handleError);
  }

  async function handleError(e: any) {
    // 清空缓存的情况下 url 会没有错
    const item = myApObject.list.audios[currentPlayIndex];
    if (!item) {
      return;
    }
    const url = item.mp3_url?.split("?")[0];
    const device_id: string = window.visitorId;
    if (url === "") {
      return;
    }
    const { code, data }: any = await httpSignMp3(item.id, url, device_id);
    if (code === 200) {
      //  更新歌单url
      myApObject.list.audios[currentPlayIndex].url = data;
      // 更新歌单列表封面
      setMusics([...myApObject.list.audios]);
      myApObject.list.switch(currentPlayIndex);
      myApObject.play();
    }
  }

  function handleEnded() {
    // 获取aplay播放列表
    let audios = myApObject.list.audios;

    // 如果是循环播放~~~~~~~~~~~~~~~~~~~~~~~~~
    if (currentPlayMode === PlayMode.Loop) {
      if (audios.length > 0) {
        // 如果播放到最后一首歌
        if (audios.length - 1 === currentPlayIndex) {
          // 有播放次数
          let maxPlayCount: number = audios[currentPlayIndex].maxPlayCount;
          if (maxPlayCount > 1) {
            audios[currentPlayIndex].maxPlayCount = maxPlayCount - 1;
          } else {
            // 没有播放次数
            currentPlayIndex = 0;
            // 重置播放次数
            const data = audios.map((item: any) => {
              item.maxPlayCount = currentPlayCount;
              return item;
            });
            setMusics(data);
          }
        } else {
          // 获取播放次数
          let maxPlayCount: number = audios[currentPlayIndex].maxPlayCount;
          if (maxPlayCount > 1) {
            audios[currentPlayIndex].maxPlayCount = maxPlayCount - 1;
          } else {
            currentPlayIndex += 1;
          }
        }
        // 切换歌单显示
        setCurrentPlay(currentPlayIndex);
        // 播放mp3
        myApObject.list.switch(currentPlayIndex);
        return;
      }
      return;
    }

    // 如果是随机播放~~~~~~~~~~~~~~~~~~~~~~
    if (currentPlayMode === PlayMode.Random) {
      const audiosIndexs = audios.map((_: any, index: number) => {
        return index;
      });
      // 如果随机播放到最后一首歌 再重新赋值播放
      if (myRandomList.length === 0) {
        myRandomList = [...audiosIndexs];
        // 重置播放次数
        const data = audios.map((item: any) => {
          item.maxPlayCount = currentPlayCount;
          return item;
        });
        setMusics(data);
        // 重置播放下标
        currentPlayIndex = myRandomList[0];
        setCurrentPlay(currentPlayIndex);
        myApObject.list.switch(currentPlayIndex);
        return;
      }

      // 有播放次数
      let idx: number = audiosIndexs[currentPlayIndex];
      if (audios[idx]?.maxPlayCount > 1) {
        audios[idx].maxPlayCount = audios[idx].maxPlayCount - 1;
      } else {
        // 播放次数用完 就随机抽取新下标
        currentPlayIndex = getRandomElement(myRandomList);
      }
      setCurrentPlay(audiosIndexs[currentPlayIndex]);
      myApObject.list.switch(audiosIndexs[currentPlayIndex]);

      return;
    }
    return;
  }

  function addPlayCountMobile(count: number) {
    if (count === 1) {
      setTagIndex(3);
      addPlayCount(3);
      return;
    }
    if (count === 3) {
      setTagIndex(9);
      addPlayCount(9);
      return;
    }
    if (count === 9) {
      setTagIndex(1);
      addPlayCount(1);
      return;
    }
  }

  function addPlayCount(count: number) {
    setTagIndex(count);
    currentPlayCount = count;
    // 设置单个歌曲的播放次数
    const data = myApObject.list.audios.map((item: any) => {
      item.maxPlayCount = count;
      return item;
    });
    setMusics(data);
  }

  // 可选：移除事件监听器以防止重复附加
  function togglePanel() {
    const panel = document.getElementById("panel");
    panel?.classList.toggle("show");
    setIsCollapse(!isCollapse);

    if (isCollapse) {
      // getPlaylist();
    }
  }

  // 获取子组件传回的数据
  const addMusic2playlist = (e: any, item: any, index: any) => {
    addMusic2List(e, item, index);
    if (isCollapse === true && isPlayListFirstLoad === true) {
      setIsCollapse(false);
      togglePanel();
      isPlayListFirstLoad = false;
    }
  };

  // 用户登录&退出的时候 更新歌单 用游客的
  function userLogout() {
    setUserName("");
    getPlaylist();
  }

  function userLogin() {}

  async function getPlaylist() {
    const device_id: string = window.visitorId;
    const { data } = await httpPostPlaylist({ device_id });

    if (!data) {
      return;
    }
    // 转换成aplay播放的样子
    const playlist = data.map((item: any) => ({
      // 保留其他属性~~~
      ...item,
      artist: item.singer,
      cover: item.album_cover_image,
      url: item.mp3_url,
    }));

    setMusics([...playlist]);
    if (playlist.length > 0) {
      // 去重
      playlist.forEach((item: any) => {
        const exists = myApObject.list.audios.some(
          (element: any) => element.id === item.id
        );
        if (!exists) {
          myApObject.list.add(item);
        }
      });
    }
    console.log(myApObject.list.audios);
    initPlayList();
  }

  function initPlayList() {
    ap?.pause();
    setIsPaused(false);

    let list: Array<number> = [];
    // musics = []
    const d = myApObject.list.audios.map((item: any, index: any) => {
      list.push(index);
      // item.selected = true;
      return item;
    });

    setMusics(d);

    // 初始化随机播放列表
    myRandomList = myApObject.list.audios.map((item: any, index: any) => {
      return index;
    });

    // 默认单曲播放3次
    addPlayCount(1);
  }

  useEffect(() => {
    // 获取cookie中的username
    const name = Cookies.get("s_username"); // => 'value'
    if (name) {
      setUserName(name);
    }
  }, [ap]);

  useEffect(() => {
    if (isPageFirstLoad) {
      isPageFirstLoad = false;
      // true
      getPlaylist();
    }
  }, []);

  return (
    //@ts-ignore
    <div style={styles.container} className="layout_index">
      {/* Outlet context属性允许父组件通过 <Outlet /> 向子路由组件传递数据或方法 */}
      <Outlet context={{ addMusic2playlist, userLogout, userLogin }} />

      <Popup
        showCloseButton={true}
        visible={popvisible2}
        onMaskClick={() => {
          setPopVisible2(false);
        }}
        onClose={() => {
          setPopVisible2(false);
        }}
        bodyStyle={{ height: 200 }}
      >
        <AutoCenter style={{ marginTop: 80, fontSize: 16 }}>
          {msg}, 请
          <Button
            onClick={() => {
              setPopVisible2(false);
              navigate("/user_login");
            }}
          >
            登录
          </Button>
          或
          <Button
            onClick={() => {
              setPopVisible2(false);
              navigate("/new_user");
            }}
          >
            注册会员
          </Button>
        </AutoCenter>
      </Popup>

      <CenterPopup
        visible={popvisible}
        onMaskClick={() => {
          setPopVisible(false);
        }}
      >
        <GoCaptcha.Slide
          // config配置数据可以自定义 是可选参数
          config={{
            width: 300,
            height: 220,
            verticalPadding: 15,
            horizontalPadding: 15,
            showTheme: true,
            title: `请拖动滑块完成拼图`,
          }}
          // data的数据由go服务端提供
          data={{
            image: sliderData?.master_bg as string, // 背景图 使用base64
            thumb: sliderData?.thumb_bg as string, // 滑块图 使用base64
            thumbX: sliderData?.thumb_x as number, // 滑块x
            thumbY: sliderData?.thumb_y as number, // 滑块y
            thumbWidth: sliderData?.thumb_w as number, // 滑块宽
            thumbHeight: sliderData?.thumb_h as number, // 滑块高
          }}
          events={{
            // click: (x: number, y: number) => {
            //   console.log("click")
            // },
            refresh: async () => {
              console.log("refresh");

              addPlayListHandler(mp3Data);
            },
            close: () => {
              setPopVisible(false);
              console.log("close");
            },
            confirm: async (dots: { x: number; y: number }) => {
              //  在业务数据中加上验证码数据
              mp3Data.x = dots.x;
              mp3Data.y = dots.y;
              // 把接口返回的validate key 给mp3Data 在请求接口时带上
              mp3Data.captchakey = sliderData?.captcha_key;
              setMp3Data(mp3Data);
              addPlayListHandler(mp3Data);
            },
          }}
        />
      </CenterPopup>

      {/* 全局播放器 */}
      <div className="footer" onClick={() => {}}>
        <div className="mp3player">
          <Mp3Player
            ap={ap}
            setAp={setAp}
            visible={visible}
            setVisible={setVisible}
            musics={musics}
            setMusics={setMusics}
            onApInit={onApInit}
          />

          <div className="control_box">
            <Space className="s1">
              <Button
                size="mini"
                color="danger"
                onClick={() => {
                  changePlayMode();
                }}
              >
                {playMode === 0 ? (
                  <>
                    <LoopOutline /> 循环播放
                  </>
                ) : (
                  <>
                    <UnorderedListOutline /> 随机播放
                  </>
                )}
              </Button>
            </Space>

            <Space
              className="s1"
              justify="center"
              align="center"
              style={{ pointerEvents: musics.length === 0 ? "none" : "all" }}
            >
              <Space
                onClick={() => {
                  onPrev();
                }}
                className="prev_button"
                justify="center"
                align="center"
              >
                <LeftOutline className="left_button" fontSize={20} />
              </Space>
              {isPaused ? (
                <>
                  <img
                    className="pause_button"
                    height={32}
                    width={32}
                    src={pause}
                    onClick={(e) => {
                      stopSelected();
                    }}
                  />
                </>
              ) : (
                <img
                  className="play_button"
                  height={32}
                  width={32}
                  src={play}
                  onClick={(e) => {
                    onPlay();
                  }}
                />
              )}
              <Space
                onClick={() => {
                  onNext();
                }}
                justify="center"
                align="center"
              >
                <RightOutline className="right_button" fontSize={20} />
              </Space>
            </Space>

            <Space className="s1" justify="end" align="center">
              {/* 播放模式 */}
              单曲播放
              {isMobile() ? (
                <>
                  <Tag
                    onClick={() => {
                      addPlayCountMobile(tagIndex);
                    }}
                    className="fontTag"
                    color={"#ff0000"}
                  >
                    {tagIndex}
                  </Tag>
                </>
              ) : (
                <>
                  <Tag
                    onClick={() => {
                      addPlayCount(1);
                    }}
                    className="fontTag"
                    color={tagIndex === 1 ? "#ff0000" : "#666"}
                  >
                    1
                  </Tag>

                  <Tag
                    onClick={() => {
                      addPlayCount(3);
                    }}
                    className="fontTag"
                    color={tagIndex === 3 ? "#ff0000" : "#666"}
                  >
                    3
                  </Tag>
                  <Tag
                    onClick={() => {
                      addPlayCount(9);
                    }}
                    className="fontTag"
                    color={tagIndex === 9 ? "#ff0000" : "#666"}
                  >
                    9
                  </Tag>
                </>
              )}
              次
            </Space>
          </div>
        </div>

        <div
          onClick={() => {
            togglePanel();
          }}
          className="collapse_button"
        >
          {isCollapse ? (
            <>
              <ArrowsAltOutline fontSize={20} /> 展开歌单
            </>
          ) : (
            <>
              <ShrinkOutline fontSize={20} /> 折叠歌单
            </>
          )}
        </div>
      </div>
      <div className="panel" id="panel">
        <div className="audioContainer">
          <Tabs activeLineMode="fixed">
            {/* <Tabs.Tab title={`播放列表(${currentSelectList.length})`} key="all"> */}
            <Tabs.Tab title={`歌曲列表`} key="all">
              <div className="audioList" ref={myRef}>
                <div style={{ textAlign: "right", margin: "8px 0" }}></div>

                <List>
                  {musics.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        onMouseEnter={(e) => {
                          // 触发条件：当鼠标指针进入目标元素，但不包含子元素。

                          setAcviteItem(index);
                          // console.log("onMouseEnter");
                        }}
                        onMouseLeave={(e: any) => {
                          // 触发条件：当鼠标指针完全离开目标元素，不包含子元素的移动

                          setAcviteItem(-1);
                          // console.log("onMouseLeave");
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onPlaySelected(item.url, index, e);
                        }}
                      >
                        <List.Item
                          className="listItem"
                          prefix={
                            <>
                              <div
                                style={{
                                  width: 38,
                                  textAlign: "center",
                                  fontSize: 13,
                                  pointerEvents: "none",
                                }}
                              >
                                <img
                                  height={12}
                                  width={12}
                                  src={playing}
                                  style={{
                                    visibility:
                                      currentPlay === index && isPaused === true
                                        ? "visible"
                                        : "hidden",
                                  }}
                                />

                                {currentPlay === index && isPaused === true ? (
                                  <></>
                                ) : (
                                  <span style={{ fontWeight: 700 }}>
                                    {index + 1}
                                  </span>
                                )}
                              </div>
                            </>
                          }
                          key={item.id}
                          arrow={false}
                        >
                          <div className="item_box" style={{ fontSize: 13 }}>
                            <Space>
                              {/* <img src={item.cover} width={20} height={20} /> */}
                              <Image
                                onError={async (e) => {
                                  // 签名到期后报错 重签
                                  const imgurl = item.cover.split("?")[0];
                                  const device_id: string = window.visitorId;
                                  const res: any = await httpSignImage(
                                    item.id,
                                    imgurl,
                                    device_id
                                  );
                                  musics[index].cover = res.data;
                                  setMusics([...musics]);
                                }}
                                src={item.cover}
                                style={{
                                  borderRadius: 3,
                                  width: 40,
                                  height: 40,
                                  pointerEvents: "none",
                                }}
                                fit="cover"
                              />

                              <div>
                                <div>{item.name}</div>
                                <div style={{ color: "#999" }}>
                                  {item.artist}
                                </div>
                              </div>
                            </Space>

                            <Space>
                              <>
                                <DeleteOutline
                                  style={{
                                    fontSize: 16,
                                    opacity: activeItem === index ? 1 : 0.01, // 这里不用有display或visivbility 不然单击无效果
                                  }}
                                  onClick={(e) => {
                                    deleteMusic(item, index);
                                  }}
                                />
                              </>
                            </Space>
                          </div>
                        </List.Item>
                      </div>
                    );
                  })}
                </List>
                <Footer label="没有啦!" style={{ borderRadius: 8 }}></Footer>
              </div>
            </Tabs.Tab>

            <Tabs.Tab title="播放历史" key="history">
              {userName ? (
                <>播放历史</>
              ) : (
                <>
                  <Button>请登录</Button>
                </>
              )}
            </Tabs.Tab>

            <Tabs.Tab title="已删除" key="delete_list">
              {userName ? (
                <> 已删除</>
              ) : (
                <>
                  <Button>请登录</Button>
                </>
              )}
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    background: "white",
  },
};
