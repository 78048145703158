
import request from '../http/request';


export async function listRecommend(PageSize:number, pageIndex:number) {
    return request(`/music/recommend/list?pageSize=${PageSize}&pageIndex=${pageIndex}`, {
      method: 'get'
    })
  }
  
  export async function listTop(PageSize:number, pageIndex:number) {
    return request(`/music/top/list?pageSize=${PageSize}&pageIndex=${pageIndex}`, {
      method: 'get'
    })
  }



export async function showBannerlist() {
  return request(`/music/playlist/listBanner`, {
    method: 'get'
  })
}


  // 精选歌单
  export async function showPlaylist(PageSize:number, pageIndex:number) {
    return request(`/music/playlist/list?pageSize=${PageSize}&pageIndex=${pageIndex}`, {
      method: 'get'
    })
  }

    // 精选歌单详情(mp3列表)
    export async function showPlaylistDetail(id:number) {
      return request(`/music/playlist`, {
        method: 'post',
        data:{id}
      })
    }
  
// 会员歌单列表
  export async function httpPostPlaylist(data:{device_id:string}) {
    return request(`/myplaylist/list`, {
      method: 'post',
      data: data
    })
  }

  // 删除音乐
  export async function httpDeleteMusic(data:{device_id:string, id:number}) {
    return request(`/myplaylist`, {
      method: 'delete',
      data: data
    })
  }


  // 所有的获取歌曲详情的方法都走这个接口
  export async function httpAddMusic2Playlist(data:any) {
    return request(`/music/detail/add`, {
      method: 'post',
      data: data
    })
  }


    // 签名mp3
    export async function httpSignMp3(id:string, url:string, device_id:string) {
      return request(`/music/detail/addMusic`, {
        method: 'post',
        data: {id, url,device_id}
      })
    }
    
    // 重签image
    
    export async function httpSignImage(id:string,cover:string, device_id:string) {
      return request(`/music/detail/addImage`, {
        method: 'post',
        data: {id,cover,device_id}
      })
    }
  


  // import request from '../http/request';

  // export async function httpLeaveMessage(title: string, message: string) {
  //   return request(`/leaveMessage`, {
  //     method: 'post',
  //     data: { title, message }
  //   });
  // }
  
  
  // export async function httpListLeaveMessage(messageCount:number) {
  //   return request(`/listLeaveMessage?message_count=${messageCount}`, {
  //     method: 'get'
  //   });
  // }
  