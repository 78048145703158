
import ReactAplayer from "react-aplayer";

import "./Mp3Player.css";
import { useEffect } from "react";


// 可以把选中的歌 选择播放 选中后自动添加到播放列表~~~

// add a new declaration (.d.ts) file containing `declare module 'react-aplayer
// Hugo是由Go语言实现的静态网站生成器。简单、易用、高效、易扩展、快速部署
export function Mp3Player({ visible, setVisible, ap, setAp,setMusics,musics,onApInit }: any) {
  // const [ap, setAp] = useState<any>(null);


  const onPlay = () => {
    // console.log("on play~~~~");




  };

  const onPause = () => {
    // console.log("on pause~~~~");
  };

  // const onError = (e:any) => {
  //   // 1 获取当前报错的url  e.currentTarget.currentSrc    "MEDIA_ELEMENT_ERROR: Empty src attribute"
  //   // 2 用fetch更新 当前的url 签名 
  //   // 3 把url发到业务服务器获取签名

  //   console.log("on mp3~~~~", e.srcElement.currentSrc);
  // };


  // example of access aplayer instance
  const onInit = (ap: any) => {
    // setAp(ap);
    onApInit(ap)
  };

  const props = {
    // fixed:true,
    // mini:true,
    listMaxHeight: "0",
    listFolded: true,
    theme: "#F57F17",
    lrcType: 1,  // 1js string 3.lrc文件
    audio: musics,
  };

  useEffect(() => {
    // console.log("mp3~~~~~");
  }, []);

  return (
 <ReactAplayer
  {...props}
  onPlay={onPlay}
  onPause={onPause}
  onInit={onInit}
  // onError={onError}
  preload="auto"
  // loop="none"
  // order="random"
  // autoplay="true"
  
/>
  );
}




/**
 * preload
      * none
          不预加载音频数据，只有当用户显式播放时，才会开始加载音频文件。
          使用场景：节省带宽，适合大多数用户可能不会播放的音频。
          metadata

          仅加载音频的元数据（如时长、编码信息），不会加载音频内容。
          使用场景：需要展示音频时长或其他基本信息，但不立即播放音频。
          auto

          尽可能地加载整个音频文件，视浏览器实现可能有所不同。
          使用场景：用户可能会播放音频，提前加载可减少播放延迟。
          * 
 */